/**
 * Do not edit directly
 * Generated on Fri, 20 Oct 2023 14:33:21 GMT
 */

[data-scheme-neutral] {
    --button-primary-enabled-background: #39499c;
    --button-primary-enabled-foreground: #ffffff;
    --button-primary-hover-background: #6e78b0;
    --button-primary-hover-foreground: #ffffff;
    --button-primary-focus-background: #6e78b0;
    --button-primary-focus-foreground: #ffffff;
    --button-primary-active-background: #263685;
    --button-primary-active-foreground: #ffffff;
    --button-primary-disabled-background: #c6c6c6;
    --button-primary-disabled-foreground: #6f6f6f;
    --card-primary-background: #6e78b0;
    --card-primary-foreground: #c8c8e6;
    --card-neutral-background: #f4f4f4;
    --card-neutral-foreground: #000000;
    --card-white-background: #ffffff;
    --card-white-foreground: #000000;
    --link-enabled-foreground: #39499c;
    --link-hover-foreground: #263685;
    --partners-background: #39499c;
    --partners-foreground: #e1e1f0;
    --text-button-foreground: #39499c;
    --tile-primary-background: #39499c;
    --tile-primary-foreground: #c8c8e6;
    --tile-neutral-background: #f4f4f4;
    --tile-neutral-foreground: #000000;
    --border-color-primary-default: #39499c;
    --border-color-primary-lighter: #6e78b0;
    --border-color-primary-light: #939bc4;
    --border-color-neutral-default: #c6c6c6;
    --border-color-neutral-darkest: #000000;
    --color-yellow-40: #f0ebb5;
    --color-yellow-50: #e6de82;
    --color-yellow-60: #e6dc6e;
    --color-yellow-70: #b09646;
    --color-yellow-80: #7b5827;
    --color-yellow-90: #3b2a13;
    --color-light-purple-40: #e1e1f0;
    --color-light-purple-50: #d6d6ea;
    --color-light-purple-60: #c8c8e6;
    --color-light-purple-70: #9595b0;
    --color-light-purple-80: #63637b;
    --color-light-purple-90: #2f2f3b;
    --color-lime-green-40: #e2fbce;
    --color-lime-green-50: #d8fabd;
    --color-lime-green-60: #c8faa0;
    --color-lime-green-70: #95bc76;
    --color-lime-green-80: #627e4b;
    --color-lime-green-90: #303d24;
    --color-red-40: #f9ada9;
    --color-red-50: #f7918c;
    --color-red-60: #ff665e;
    --color-red-70: #bf4c46;
    --color-red-80: #7f332f;
    --color-red-90: #401a18;
    --color-grey-40: #afaeb9;
    --color-grey-50: #9391a1;
    --color-grey-60: #6d6b82;
    --color-grey-70: #504d76;
    --color-grey-80: #342e6c;
    --color-grey-90: #15122b;
    --color-dark-blue-40: #939bc4;
    --color-dark-blue-50: #6e78b0;
    --color-dark-blue-60: #39499c;
    --color-dark-blue-70: #263685;
    --color-dark-blue-80: #132270;
    --color-dark-blue-90: #080f30;
    --color-neutral-10: #f4f4f4;
    --color-neutral-20: #e0e0e0;
    --color-neutral-30: #c6c6c6;
    --color-neutral-40: #a8a8a8;
    --color-neutral-50: #8d8d8d;
    --color-neutral-60: #6f6f6f;
    --color-neutral-70: #525252;
    --color-neutral-80: #393939;
    --color-neutral-90: #262626;
    --color-neutral-100: #161616;
    --color-neutral-white: #ffffff;
    --color-neutral-black: #000000;
    --color-positive-40: #34c759;
    --color-positive-50: #2fb350;
    --color-positive-60: #2aa148;
    --color-positive-70: #269141;
    --color-positive-80: #22823a;
    --color-informative-40: #2680eb;
    --color-informative-50: #1473e6;
    --color-informative-60: #0d66d0;
    --color-informative-70: #095aba;
    --color-informative-80: #084ea1;
    --color-notice-40: #e68619;
    --color-notice-50: #da7b11;
    --color-notice-60: #cb6f10;
    --color-notice-70: #bd640d;
    --color-notice-80: #864906;
    --color-negative-40: #ff3a46;
    --color-negative-50: #e5343f;
    --color-negative-60: #ce2f39;
    --color-negative-70: #ba2a33;
    --color-negative-80: #a7262e;
    --syntax-background-neutral-default: #ffffff;
    --syntax-background-neutral-dark: #f4f4f4;
    --syntax-background-neutral-darkest: #000000;
    --syntax-background-primary-lightest: #939bc4;
    --syntax-background-primary-light: #6e78b0;
    --syntax-background-primary-default: #39499c;
    --syntax-background-primary-dark: #263685;
    --syntax-background-primary-darker: #132270;
    --syntax-background-primary-darkest: #080f30;
    --syntax-color-action-enabled-background: #39499c;
    --syntax-color-action-enabled-foreground: #ffffff;
    --syntax-color-action-focus-background: #6e78b0;
    --syntax-color-action-focus-foreground: #ffffff;
    --syntax-color-action-hover-background: #6e78b0;
    --syntax-color-action-hover-foreground: #ffffff;
    --syntax-color-action-active-background: #263685;
    --syntax-color-action-active-foreground: #ffffff;
    --syntax-color-input-enabled-background: #ffffff;
    --syntax-color-input-enabled-border: #c6c6c6;
    --syntax-color-input-focus-background: #ffffff;
    --syntax-color-input-focus-border: #39499c;
    --syntax-color-input-hover-background: #ffffff;
    --syntax-color-input-hover-border: #39499c;
    --syntax-color-input-active-background: #ffffff;
    --syntax-color-input-active-border: #39499c;
    --syntax-color-input-invalid-background: #ffffff;
    --syntax-color-input-invalid-border: #ff3a46;
    --syntax-color-input-disabled-background: #f4f4f4;
    --syntax-color-input-disabled-border: #c6c6c6;
    --syntax-color-disabled-background: #c6c6c6;
    --syntax-color-disabled-foreground: #6f6f6f;
    --syntax-color-error-foreground: #ff3a46;
    --syntax-disabled-color-default: #c6c6c6;
    --syntax-disabled-color-dark: #6f6f6f;
    --syntax-pillar-color-collection-default: #c8c8e6;
    --syntax-pillar-color-collection-lighter: #e1e1f0;
    --syntax-pillar-color-festival-default: #e6dc6e;
    --syntax-pillar-color-festival-lighter: #f0ebb5;
    --syntax-pillar-color-institute-default: #ff665e;
    --syntax-pillar-color-institute-lighter: #f9ada9;
    --syntax-pillar-color-professionals-default: #c8faa0;
    --syntax-pillar-color-professionals-lighter: #e2fbce;
    --syntax-primary-color-light: #939bc4;
    --syntax-primary-color-lighter: #6e78b0;
    --syntax-primary-color-default: #39499c;
    --syntax-primary-color-darker: #263685;
    --syntax-primary-color-dark: #132270;
    --syntax-primary-color-darkest: #080f30;
    --syntax-text-color-neutral-muted: #504d76;
    --syntax-text-color-neutral: #39499c;
    --syntax-text-color-default: #000000;
    --syntax-text-color-muted: #8d8d8d;
    --syntax-text-color-on-dark: #ffffff;
    --syntax-text-color-on-primary: #ffffff;
}
